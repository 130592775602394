<template>
  <footer class="bg-bgGreen pt-12 pb-5 font-source px-2">
    <section
      class="flex flex-col lg:flex-row lg:justify-between gap-5 px-4 lg:px-20"
    >
      <div class="text-white text-base lg:text-left w-full lg:w-4/12">
        <logo class="h-12" />

        <p class="text-left lg:text-left mt-1 lg:mt-4">
          We want to acknowledge contributions of Abuja residents in fulfilment
          of their civic responsibilities through payment of taxes.
        </p>
      </div>

      <div
        class="flex flex-col lg:flex-row gap-10 justify-center lg:justify-end mb-6 text-sm lg:text-base w-full lg:w-6/12"
      >
        <ul title="Accounts" class="w-2/5">
          <li>
            <router-link :to="{ name: 'TaxPayer Login' }">
              Tax Payer Login
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Login' }">Admin/Agent Login</router-link>
          </li>
        </ul>

        <ul title="Quick Links" class="w-2/5">
          <li>
            <router-link :to="{ name: 'Track' }">Track Transaction</router-link>
          </li>
          <li><router-link to="">Tax Policy</router-link></li>
          <li><router-link to="">Tax Types</router-link></li>
        </ul>

        <ul title="Contact" class="w-3/5">
          <li>
            <a href="mailto:esirs@enugustate.gov.ng">esirs@enugustate.gov.ng</a>
          </li>
          <li>
            <a href="tel:+2347084788833">07084788833</a> &nbsp;|&nbsp;
            <a href="tel:+2347062634378">07062634378</a>
          </li>
          <li>
            Area 11, FCTA Secretariat, 1 Kapital Rd, Garki, Abuja 900103,
            Federal Capital Territory
          </li>
        </ul>
      </div>
    </section>

    <hr class="border-gray-400" />

    <section
      class="flex flex-col lg:flex-row lg:justify-between pt-4 px-4 lg:px-20"
    >
      <p class="text-aeroBlue text-center lg:text-left">
        © {{ year }} - {{ APP_NAME }}. All rights reserved.
      </p>

      <p class="text-aeroBlue text-center text-sm">
        Design & Developed by
        <img
          src="@/assets/images/powered_by.png"
          alt="ANS Logo"
          class="h-11 inline mb-1"
        />
      </p>
    </section>
  </footer>
</template>

<script>
import Logo from "./Logo.vue";
export default {
  components: { Logo },
  name: "Footer",

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
ul[title]::before {
  content: attr(title);
  display: block;
  font-weight: bold;
  @apply text-white;
}
li,
li a {
  @apply text-aeroBlue py-2 hover:underline;
}
</style>
