<template>
  <section class="mt-5 lg:mt-9">
    <h3 class="text-ansGreen font-bold px-2 my-2">
      Hello
      <span class="text-bgGreen font-bold capitalize">
        <span v-if="userData.firstName">
          {{ userData.firstName }} {{ userData.surname }},
        </span>
        <span v-else> {{ userData.businessName }}, </span>
      </span>
    </h3>

    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Select the revenue(s) you would like to pay for
    </h4>

    <div class="px-4 mt-2.5">
      <label for="revenue">
        Revenue Item
        <span class="text-red-400">*</span>
      </label>

      <v-select
        id="revenue"
        label="revenueName"
        :reduce="(b) => b.revenueCode"
        :options="revenueItems"
        v-model="form.revenue"
        :filterable="false"
        @search="onSearch"
        placeholder="-- select revenue --"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No results found for <em>{{ search }}</em
            >.
          </template>
          <span v-else clas="text-gray-400 lowercase text-xs">
            Start typing to search for a revenue
          </span>
        </template>
      </v-select>
    </div>

    <div class="px-4 mt-4">
      <label for="duration">
        Duration
        <span class="text-red-400">*</span>
      </label>
      <v-select
        id="duration"
        label="durationName"
        :reduce="(b) => b"
        :options="durations"
        v-model="form.duration"
        placeholder="-- select duration --"
      ></v-select>
    </div>

    <div class="px-4 mt-4">
      <label for="year">
        Year
        <span class="text-red-400">*</span>
      </label>
      <v-select
        id="year"
        label="label"
        :reduce="(b) => b.value"
        :options="years"
        v-model="form.year"
        placeholder="-- select a year --"
      ></v-select>
    </div>

    <!-- Table section -->
    <div v-if="$store.getters['general/revenues'].length" class="px-4 mt-8">
      <table>
        <tr
          v-for="(rev, i) in $store.getters['general/revenues']"
          :key="i"
          class="w-full text-sm"
        >
          <td class="w-1/12">{{ i + 1 }}.</td>
          <td class="w-4/12 uppercase">
            {{ rev.revenueName }} ({{ rev.durationName }})
          </td>
          <td class="w-2/12 text-center">
            <p>{{ rev.year }}</p>
          </td>
          <td class="w-3/12 text-center">
            <p>
              {{ rev.amount | toCurrency }}
            </p>
          </td>
          <td class="w-2/12">
            <button class="text-red-400 px-2" @click="deleteRev(rev, i)">
              Remove
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="2">Total:</td>
          <td colspan="3">{{ reveuneTotal | toCurrency }}</td>
        </tr>
      </table>
    </div>

    <div class="flex flex-col gap-5 px-4 mt-8 pb-3">
      <button
        :disabled="!addReady"
        @click="addRevenue"
        class="border border-ansGreen bg-ansLemon text-ansGreen w-full text-center py-3 rounded font-semibold text-xl"
      >
        Add
        <span v-if="$store.getters['general/revenues'].length">more </span>
        revenue item
      </button>

      <button
        v-if="$store.getters['general/revenues'].length"
        :disabled="verifying"
        @click="generateInvoice"
        class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
      >
        <span>Generate Invoice</span>
        <spinner v-if="verifying" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "AddRevenue",

  props: {
    userData: {
      type: Object,
      required: true,
      default: () => ({
        firstName: "Chika",
        surname: "Job",
        businessName: "Swizel Technologies",
      }),
    },
  },

  data() {
    return {
      verifying: false,
      form: {
        revenue: null,
        duration: null,
        year: null,
      },
      revenueItems: [],
      revenues: [],
      durations: [],
      revenueCode: null,
    };
  },

  computed: {
    addReady() {
      return !!this.form.duration && this.form.revenue && this.form.year;
    },
    years() {
      const yearArr = [];
      for (let i = new Date().getFullYear(); i >= 2000; i--) {
        yearArr.push({
          label: i,
          value: i,
        });
      }
      return yearArr;
    },
    reveuneTotal() {
      return this.$store.getters["general/revenues"].reduce(
        (a, b) => a + Number(b.amount),
        0
      );
    },
  },
  watch: {
    async "form.revenue"(nv) {
      if (!nv) {
        this.revenueCode = null;
        this.durations = [];
        return;
      }

      this.revenueCode = nv;
      await this.getSubcritptions();
    },
  },

  methods: {
    deleteRev(rev, id) {
      this.$store.commit("general/delRevenueItem", id);
    },
    addRevenue() {
      this.$store.commit("general/addRevenueItems", {
        ...this.form.duration,
        year: this.form.year,
      });
      this.form.revenue = null;
      this.form.duration = null;
      this.form.year = null;
    },
    async generateInvoice() {
      try {
        this.verifying = true;

        const res = await this.$http.post(
          "invoice/create-invoice",
          {
            userId: this.$store.getters["general/ind"].userId,
            subscriptions: this.$store.getters["general/revenues"].map(
              (rev) => ({ id: rev._id, year: rev.year })
            ),
          },
          {
            headers: { noauth: true },
          }
        );
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$store.commit("general/updateInvoiceData", data.invoice);
        this.$emit("done");
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        this.getRevenues(search, loading);
      }
    },
    async getRevenues(search, loading) {
      try {
        loading(true);
        const res = await this.$http.get("/revenue/items?search=" + search, {
          headers: { noauth: true },
        });

        if (!res) {
          this.revenueItems = [];
          return;
        }

        const { data } = res;
        this.revenueItems = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },
    async getSubcritptions() {
      try {
        const res = await this.$http.get(
          "/subscription?limit=100&search=" + this.revenueCode,
          {
            headers: { noauth: true },
          }
        );

        if (!res) {
          this.durations = [];
          return;
        }

        const { data } = res;
        this.durations = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
table {
  @apply border-collapse bg-oldSilver w-full text-base font-semibold text-cadet;
}
td {
  @apply py-2.5 border-b-2 border-white;
}
table tr:last-of-type {
  @apply font-bold text-lg lg:text-2xl bg-ansLemon;
}
table tr:last-of-type td {
  @apply border-t-[20px];
}
table td:first-of-type {
  @apply pl-5;
}
table td:last-of-type {
  @apply pl-5 text-right pr-5;
}
</style>
