var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('section',{staticClass:"mt-24 lg:mt-20 block lg:flex justify-between font-source"},[_c('div',{staticClass:"hidden lg:flex w-1/2 flex-col justify-center items-start pl-32 pr-4 text-white",style:({
        '--bgImage':
          'url(' +
          require('@/assets/images/banners/' + _vm.PROJECT_NAME + '.png') +
          ')',
      }),attrs:{"id":"side-banner"}},[_vm._m(0),_c('p',{staticClass:"text-xl mt-4"},[_vm._v(" Pay your revenue online, All with the click of a button. ")])]),_c('div',{staticClass:"w-full lg:w-1/2 px-4 lg:px-24 mt-13 lg:mt-32"},[_c('h3',{staticClass:"text-ansBlack font-semibold text-2xl lg:text-4xl"},[_vm._v(" Welcome to FCTA ")]),_c('h5',{staticClass:"text-cadet text-base lg:text-2xl mt-0 lg:mt-2"},[_vm._v(" Enter detail to reset password ")]),_c('section',{staticClass:"mt-8"},[_c('div',[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userData),expression:"form.userData"}],staticClass:"input",attrs:{"id":"username","type":"text","placeholder":"Enter email or phone"},domProps:{"value":(_vm.form.userData)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "userData", $event.target.value)}}})]),_c('div',{staticClass:"mt-13"},[_c('button',{staticClass:"bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center",attrs:{"disabled":_vm.processing || !_vm.form.userData},on:{"click":_vm.verify}},[_c('span',[_vm._v("Verify")]),(_vm.processing)?_c('spinner'):_vm._e()],1)])]),_c('section',{staticClass:"mt-1"},[_c('div',{staticClass:"flex justify-center mt-10"},[_c('router-link',{staticClass:"text-ansGreen text-sm",attrs:{"to":{ name: 'TaxPayer Login' }}},[_vm._v(" Login ")])],1),_c('div',{staticClass:"flex justify-center mt-16 lg:mt-20 text-cadet text-sm"},[_vm._v(" © "+_vm._s(_vm.year)+" - "+_vm._s(_vm.APP_NAME)+". All rights reserved. ")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-5xl font-light -mt-32"},[_vm._v(" Pay your revenue so"),_c('br'),_vm._v(" we "),_c('span',{staticClass:"font-bold"},[_vm._v("can grow together!")])])
}]

export { render, staticRenderFns }