<template>
  <div>
    <Header />

    <section class="mt-24 lg:mt-20 block lg:flex justify-between font-source">
      <div
        id="side-banner"
        class="hidden lg:flex w-1/2 flex-col justify-center items-start pl-32 pr-4 text-white"
        :style="{
          '--bgImage':
            'url(' +
            require('@/assets/images/banners/' + PROJECT_NAME + '.png') +
            ')',
        }"
      >
        <h3 class="text-5xl font-light -mt-32">
          Pay your revenue so<br />
          we <span class="font-bold">can grow together!</span>
        </h3>

        <p class="text-xl mt-4">
          Pay your revenue online, All with the click of a button.
        </p>
      </div>

      <!-- Form side -->
      <div class="w-full lg:w-1/2 px-4 lg:px-24 mt-13 lg:mt-32">
        <h3 class="text-ansBlack font-semibold text-2xl lg:text-4xl">
          Welcome to {{ APP_NAME }}
        </h3>
        <h5 class="text-cadet text-base lg:text-2xl mt-0 lg:mt-2">
          Reset password to continue
        </h5>

        <!-- Loading and Verifying -->
        <section v-if="!verified" class="mt-8">
          <div
            v-if="verifying"
            class="mt-13 flex flex-col justify-center items-center"
          >
            <spinner class="w-14 h-14" color="ansGreen"></spinner>
            <p class="text-ansGreen italic mt-2">verifying ...</p>
          </div>

          <div v-else class="mt-13 w-full flex flex-col justify-center">
            <img
              src="@/assets/images/broken_link.svg"
              alt="icon"
              class="w-32 h-32 transform rotate-180"
            />

            <p class="text-red-400 font-bold mt-2">
              The link is broken or invalid ...
            </p>
          </div>
        </section>

        <!-- Password form -->
        <section v-else class="mt-8">
          <div class="mt-4">
            <label for="newPassword">New Password</label>
            <input
              id="newPassword"
              type="password"
              class="input"
              placeholder="Enter new password"
              v-model="form.password"
            />
            <small
              class="text-xs text-red-500 font-semibold"
              v-if="passwordError"
            >
              At least one UPPERCASE, one lowercase, one digit, one special
              character, and minimum length of 8 and maximum of 40
            </small>
          </div>

          <div class="mt-4">
            <label for="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              type="password"
              class="input"
              placeholder="Confirm password"
              v-model="form.confirmPassword"
            />
          </div>

          <div class="mt-13">
            <button
              :disabled="processing || !resetReady"
              @click="resetPassword"
              class="bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center"
            >
              <span>Update Password</span>
              <spinner v-if="processing" />
            </button>
          </div>
        </section>

        <section class="mt-1">
          <div class="flex justify-center mt-10">
            <router-link :to="{ name: 'Login' }" class="text-ansGreen text-sm">
              Login
            </router-link>
          </div>

          <div class="flex justify-center mt-16 lg:mt-20 text-cadet text-sm">
            © {{ year }} - {{ APP_NAME }}. All rights reserved.
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
export default {
  name: "ResetPassword",

  components: {
    Header,
  },

  data() {
    return {
      verifying: true,
      verified: false,
      processing: false,
      form: {
        userData: null,
        token: null,
        password: null,
        confirmPassword: null,
      },
    };
  },

  computed: {
    passwordValid() {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.form.password
      );
    },
    passwordError() {
      return !!this.form.password & !this.passwordValid;
    },
    year() {
      return new Date().getFullYear();
    },
    resetReady() {
      return (
        this.passwordValid && this.form.password == this.form.confirmPassword
      );
    },
  },

  async created() {
    if (this.$route.query && (!this.$route.query.tp || !this.$route.query.pd)) {
      this.$router.push({ name: "TaxPayer Forgot Password" });
      return;
    }
    const { tp, pd } = this.$route.query;
    this.form.userData = pd;
    this.form.token = tp;
    await this.verify();
  },

  methods: {
    async verify() {
      try {
        this.verifying = true;

        const res = await this.$http.post(
          "/auth/verify-token",
          {
            userData: this.form.userData,
            token: this.form.token,
          },
          {
            headers: { noauth: true },
          }
        );

        this.verifying = false;
        if (!res) {
          return;
        }
        this.verified = true;
      } catch (error) {
        this.verifying = false;
        console.log({ error });
      }
    },

    async resetPassword() {
      try {
        this.processing = true;

        const res = await this.$http.post(
          "/taxpayer/reset-password",
          this.form,
          {
            headers: { noauth: true },
          }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
#side-banner {
  @apply bg-bgGreen;
  background-image: var(--bgImage);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100vh - 80px);
}
</style>
