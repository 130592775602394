<template>
  <div class="flex justify-content-between gap-3 mt-1">
    <HistoryLine :done="receiptData.done && !!receiptData.receipt.length" />

    <div class="border border-gray-300 rounded p-6 mt-4 w-full">
      <ul v-if="receiptData.receipt.length" class="divide-y">
        <li
          v-for="(rec, x) in receiptData.receipt"
          :key="x"
          class="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3"
        >
          <h4>{{ rec.receiptInfo }}</h4>

          <div class="flex flex-wrap gap-3">
            <button
              :disabled="sendingReceipt"
              @click="$emit('showview', rec.receiptNumber)"
              class="text-white bg-ansGreen rounded text-xs py-2 px-3 lg:px-5"
            >
              View receipt
            </button>

            <button
              :disabled="sendingReceipt"
              @click="sendReceipt(rec.receiptNumber)"
              class="flex justify-center items-center text-xs lg:text-base gap-2 py-2 px-3 lg:px-5 rounded border border-gray-300"
            >
              <img
                src="@/assets/images/email/email_send.svg"
                alt="icons"
                class="hidden lg:inline"
              />
              <span>Send via email</span>
              <spinner v-if="sendingReceipt" class="" color="ansGreen" />
            </button>

            <button
              @click="printReceipt(rec.receiptNumber)"
              class="flex justify-center items-center text-xs lg:text-base gap-2 py-2 px-3 lg:px-5 rounded border border-gray-300"
            >
              <img
                src="@/assets/images/email/print.svg"
                alt="icons"
                class="lg:inline w-5 h-5 hidden"
              />
              <span>Print</span>
            </button>
          </div>
        </li>
      </ul>

      <div v-else class="flex gap-3 justify-evenly items-center">
        <h4 class="font-bold text-cadet text-xs">No receipt generated</h4>

        <button
          :disabled="getting"
          @click="generateReceipt"
          class="bg-ansGreen py-3.5 px-14 text-white rounded text-xs"
        >
          Generate Receipt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryLine from "@/components/track/HistoryLine.vue";
export default {
  name: "ReceiptCard",

  components: {
    HistoryLine,
  },

  props: {
    receiptData: {
      type: Object,
      default: () => ({
        done: false,
        receipt: [],
        invoiceNumber: null,
      }),
    },
  },

  data() {
    return {
      done: true,
      getting: false,
      sendingReceipt: false,
    };
  },

  methods: {
    async sendReceipt(receiptNumber) {
      try {
        this.sendingReceipt = true;

        const res = await this.$http.post(
          "receipt/email/receipt",
          {
            receiptNumber,
            baseUrl: `${window.location.origin}/receipt`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.sendingReceipt = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.sendingReceipt = false;
      }
    },

    async getReceipt(receiptNumber) {
      try {
        this.getting = true;

        const res = await this.$http.post(
          "/receipt/" + receiptNumber,
          {
            baseUrl: `${window.location.origin}/receipt`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;

        return data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async printReceipt(receiptNumber) {
      const divContents = await this.getReceipt(receiptNumber);

      const a = window.open("", "", "");
      a.document.write(
        "<html><head><title>" + document.title + "</title></head>"
      );
      a.document.write("<body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      setTimeout(() => {
        a.document.close();
        a.focus();
      }, 300);

      setTimeout(() => {
        a.print();
        a.close();
        return true;
      }, 700);
    },

    async generateReceipt() {
      this.$emit("generaterecipt");
    },
  },
};
</script>

<style scoped></style>
