<template>
  <div>
    <Header />

    <section class="block lg:flex justify-between font-source mt-24 lg:mt-20">
      <div
        id="side-banner"
        class="hidden lg:flex w-1/2 flex-col justify-center items-start pl-32 pr-4 text-white"
        :style="{
          '--bgImage':
            'url(' +
            require('@/assets/images/banners/' + PROJECT_NAME + '.png') +
            ')',
        }"
      >
        <h3 class="text-5xl font-light -mt-32">
          Pay your revenue so<br />
          we <span class="font-bold">can grow together!</span>
        </h3>

        <p class="text-xl mt-4">
          Pay your revenue online, All with the click of a button.
        </p>
      </div>

      <!-- Form side -->
      <div class="w-full lg:w-1/2 px-4 lg:px-24 mt-13 lg:mt-32">
        <h3 class="text-ansBlack font-semibold text-2xl lg:text-4xl">
          Welcome to {{ APP_NAME }}
        </h3>

        <div
          class="flex gap-4 items-center border border-[#E2E4EB] bg-mintCream rounded-md p-0.5 w-max"
          id="btn-nav"
        >
          <button
            :class="`${!isCoporate ? 'active' : ''}`"
            @click="switchUi(false)"
          >
            Individual Tax Payer
          </button>
          <button
            :class="`${isCoporate ? 'active' : ''}`"
            @click="switchUi(true)"
          >
            Corporate Tax Payer
          </button>
        </div>

        <!-- Login form section 1 -->
        <section class="mt-8" v-if="step == 1">
          <div>
            <label for="userData">
              <span v-if="!isCoporate">NIN</span>
              <span v-else>TIN</span>
              <span class="text-red-400">*</span>
            </label>
            <input
              id="userData"
              type="text"
              class="input"
              pattern="/\d*$/"
              onKeyPress="if(this.value.length==11) return false;"
              placeholder="Enter your Identification Number"
              v-model="form.userData"
            />
          </div>

          <div class="mt-13">
            <button
              :disabled="processing || !checkReady"
              @click="checkUser"
              class="bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center"
            >
              <span>Proceed</span>
              <spinner v-if="processing" />
            </button>
          </div>
        </section>

        <!-- Login form section 2 otp -->
        <section class="mt-8" v-if="step == 2">
          <div class="mt-3">
            <p class="text-ansGreen">
              <span>
                Enter the OTP sent to your
                {{ isCoporate ? "company email address" : "phone number" }}
              </span>
              <br />
              <small v-if="otpMessage" class="text-ansBlack font-semibold">{{
                otpMessage
              }}</small>
            </p>
          </div>

          <div class="mt-4">
            <label for="token">OTP</label>
            <input
              id="token"
              type="number"
              class="input"
              placeholder="Enter OTP"
              v-model="form.token"
            />
          </div>

          <div class="mt-4">
            <label for="newPassword">New Password</label>
            <input
              id="newPassword"
              type="password"
              class="input"
              placeholder="Enter a password"
              v-model="form.password"
            />
            <small
              v-if="form.password && form.password.length < 5"
              class="text-purple-500"
            >
              Password length must be upto 5
            </small>
          </div>

          <div class="mt-4">
            <label for="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              type="password"
              class="input"
              placeholder="Re-type the password"
              v-model="form.confirmPassword"
            />
            <small
              v-if="form.password && form.confirmPassword && !passwordMatch"
              class="text-purple-500"
            >
              Passwords does not match
            </small>
          </div>

          <div class="mt-13">
            <button
              :disabled="processing || !loginReady"
              @click="setPassword"
              class="bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center"
            >
              <span>Continue</span>
              <spinner v-if="processing" />
            </button>
          </div>
        </section>

        <!-- Login form section 3 password -->
        <section class="mt-8" v-if="step == 3">
          <div class="mt-4">
            <label for="password">Password</label>
            <input
              id="password"
              type="password"
              class="input"
              placeholder="Enter password"
              v-model="form.password"
            />
          </div>

          <div class="mt-13">
            <button
              :disabled="processing || !loginReady"
              @click="login"
              class="bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center"
            >
              <span>Continue</span>
              <spinner v-if="processing" />
            </button>
          </div>
        </section>

        <section class="mt-1">
          <div class="flex justify-center mt-10 gap-2 items-center">
            <router-link
              :to="{ name: 'TaxPayer Forgot Password' }"
              class="text-ansGreen text-sm hover:underline"
            >
              Forgot Password
            </router-link>
            |
            <router-link
              :to="{ name: 'Login' }"
              class="text-ansGreen text-sm hover:underline"
            >
              Agent/Admin Login
            </router-link>
          </div>

          <div class="flex justify-center mt-16 lg:mt-20 text-cadet text-sm">
            © {{ year }} - {{ APP_NAME }}. All rights reserved.
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
export default {
  name: "LoginTaxPayer",

  components: {
    Header,
  },

  data() {
    return {
      isCoporate: false,
      step: 1,
      processing: false,
      otpMessage: null,
      form: {
        userData: null,
        token: null,
        password: null,
        confirmPassword: null,
      },
      user: null,
    };
  },

  mounted() {
    this.$store.commit("general/logout");
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },
    checkReady() {
      return Boolean(this.form.userData) && this.form.userData.length > 5;
    },
    passwordMatch() {
      return this.form.password === this.form.confirmPassword;
    },
    passwordReady() {
      return (
        this.checkReady &&
        Boolean(this.form.password) &&
        this.form.password.length > 5 &&
        this.form.password == this.form.confirmPassword
      );
    },
    loginReady() {
      return (
        this.checkReady &&
        Boolean(this.form.password) &&
        this.form.password.length > 4
      );
    },
  },

  methods: {
    switchUi(val) {
      this.isCoporate = val;
      this.form.userData = null;
      this.step = 1;
    },

    async checkUser() {
      if (!this.checkReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.processing = true;

        const res = await this.$http.post(
          "/taxpayer/initiate-login",
          this.form,
          {
            headers: { noauth: true },
          }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        if (data.data?.isOnboarded) {
          this.step = 3;
          return;
        }

        this.otpMessage = data.message;
        this.$swal({
          icon: "info",
          text: data.message,
        });

        this.step = 2;
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },

    async login() {
      if (!this.loginReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.processing = true;

        const res = await this.$http.post("/taxpayer/login", this.form, {
          headers: { noauth: true },
        });

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.user ?? {},
          token: data.token,
        });
        this.$store.commit("general/login");

        if (this.$route.query.nextUrl) {
          this.$router.push(this.$route.query.nextUrl);
          return;
        }

        this.$router.push({ name: "TaxPayer Dashboard" });
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },

    async setPassword() {
      if (!this.loginReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.processing = true;

        const res = await this.$http.post(
          "/taxpayer/create-password",
          this.form,
          {
            headers: { noauth: true },
          }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
          token: data.token,
        });
        this.$store.commit("general/login");

        if (this.$route.query.nextUrl) {
          this.$router.push(this.$route.query.nextUrl);
          return;
        }

        this.$router.push({ name: "TaxPayer Dashboard" });
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
#side-banner {
  @apply bg-bgGreen;
  background-image: var(--bgImage);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100vh - 80px);
}
#btn-nav button {
  @apply text-[#828DA9] p-2 rounded;
}
#btn-nav button.active {
  @apply text-white bg-ansGreen font-semibold;
}
</style>
