<template>
  <div class="pb-10 mx-auto" style="max-width: 1200px">
    <InvoiceCard v-if="isset" :invoiceNumber="invoiceNumber" />
  </div>
</template>

<script>
import InvoiceCard from "@/components/home/Invoice.vue";
export default {
  name: "Invoice",
  components: {
    InvoiceCard,
  },
  data() {
    return {
      invoiceNumber: null,
      isset: false,
    };
  },
  mounted() {
    console.log(this.$route.params);
    this.invoiceNumber = this.$route.params.id;
    this.isset = true;
  },
};
</script>

<style scoped></style>
