<template>
  <div class="flex flex-col items-center justify-start gap-0.5">
    <div class="">
      <div
        :class="`${
          done ? 'bg-ansGreen' : 'bg-ansLemon'
        } h-8 w-8 lg:w-14 lg:h-14 rounded-full flex justify-center items-center`"
      >
        <!-- } h-8 w-8 lg:w-14 lg:h-14 rounded-full flex justify-center items-center`" -->
        <i v-if="done" class="fas fa-check text-white"></i>
        <i v-else class="fas fa-times text-ansGreen"></i>
      </div>
    </div>
    <div
      class="bg-ansGreen w-[3px] flex flex-col h-[calc(100%-32px)] lg:h-[calc(100%-56px)]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "HistoryLine",

  data() {
    return {
      step: 1,
    };
  },

  props: {
    done: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
