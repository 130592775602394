<template>
  <div class="pb-10 mx-auto" style="max-width: 1200px">
    <ReceiptCard v-if="isset" :receiptNumber="receiptNumber" />
  </div>
</template>

<script>
import ReceiptCard from "@/components/home/Receipt.vue";
export default {
  name: "Receipt",
  components: {
    ReceiptCard,
  },
  data() {
    return {
      receiptNumber: null,
      isset: false,
    };
  },
  mounted() {
    console.log(this.$route.params);
    this.receiptNumber = this.$route.params.id;
    this.isset = true;
  },
};
</script>

<style scoped></style>
