<template>
  <div class="font-source mt-20">
    <!-- Icons and lines -->
    <div
      class="flex gap-4 items-center border border-[#E2E4EB] bg-[#F9F9F9] rounded-md p-0.5 mx-4 w-max"
      id="btn-nav"
    >
      <button
        :class="`${!isCoporate ? 'active' : ''}`"
        @click="switchUi(false)"
      >
        Individual Tax Payer
      </button>
      <button :class="`${isCoporate ? 'active' : ''}`" @click="switchUi(true)">
        Corporate Tax Payer
      </button>
    </div>

    <PersonalInfo
      v-if="$store.state.general.step <= 4 && !isCoporate"
      @done="userDone"
    />

    <CoporateInfo
      v-else-if="$store.state.general.step <= 4 && isCoporate"
      @done="userDone"
    />

    <AddRevenue
      v-if="$store.state.general.step == 5"
      @done="generateInvoice"
      :userData="user"
    />
  </div>
</template>

<script>
import AddRevenue from "@/components/home/AddRevenue.vue";
import PersonalInfo from "@/components/home/PersonalInfo.vue";
import CoporateInfo from "@/components/home/CoporateInfo.vue";
export default {
  name: "GenerateInvoice",

  components: {
    PersonalInfo,
    CoporateInfo,
    AddRevenue,
  },

  data() {
    return {
      isCoporate: false,
      user: null,
      invoice: null,
    };
  },

  methods: {
    switchUi(val) {
      this.isCoporate = val;
      this.$store.commit("general/updateStep", 1);
      this.$router.push({
        name: "Home",
        query: { st: 1 },
      });
    },
    generateInvoice() {
      this.$emit("doned");
    },
    userDone(user) {
      this.user = user;
    },
    next() {
      const step = this.$store.state.general.step + 1;
      this.$store.commit("general/updateStep", step);
      this.$router.push({
        name: "Home",
        query: { st: step },
      });
    },
  },
};
</script>

<style scoped>
#btn-nav button {
  @apply text-[#828DA9] p-2 rounded;
}
#btn-nav button.active {
  @apply text-white bg-ansGreen font-semibold;
}
</style>
