<template>
  <section class="mt-5 lg:mt-9">
    <!-- <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Personal Information
    </h4> -->

    <!-- Section 1 -->
    <div v-if="$store.state.general.step == 1">
      <div class="px-4 mt-5">
        <label for="tin">
          Tax Identification Number (TIN)
          <span class="text-red-400">*</span>
        </label>
        <input
          type="number"
          class="input"
          id="tin"
          onKeyPress="if(this.value.length==11) return false;"
          placeholder="Enter your TIN"
          v-model="form.tin"
        />
      </div>

      <div class="flex justify-center px-4 mt-8 pb-3">
        <button
          :disabled="verifying || !stepOneReady"
          @click="verifyTin"
          class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </div>

    <!-- Section 2 -->
    <div v-if="$store.state.general.step == 2">
      <div class="px-4 mt-8">
        <label for="businessName">
          Company/Business Name
          <span class="text-red-400">*</span>
        </label>
        <input
          type="text"
          class="input"
          id="businessName"
          placeholder="Enter your business name"
          v-model="form.businessName"
        />
      </div>

      <div class="px-4 mt-8">
        <label for="phone">
          Company Phone Number
          <span class="text-red-400">*</span>
        </label>
        <input
          type="number"
          class="input"
          id="phone"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
          v-model="form.phone"
          placeholder="Enter your phone number"
        />
      </div>

      <div class="px-4 mt-8">
        <label for="email">
          Company Email Address
          <span class="text-red-400">*</span>
        </label>
        <input
          type="email"
          class="input"
          id="email"
          placeholder="Enter your email"
          v-model="form.email"
        />
      </div>

      <div class="px-4 mt-8">
        <label for="businessAddress">
          Company Address
          <span class="text-red-400">*</span>
        </label>
        <input
          type="text"
          class="input"
          id="businessAddress"
          placeholder="Enter your address"
          v-model="form.businessAddress"
        />
      </div>

      <div class="flex justify-center px-4 mt-8 pb-3">
        <button
          @click="prev"
          class="bg-ansLemon text-ansGreen w-full text-center py-3 rounded font-semibold text-xl"
        >
          Back
        </button>

        <button
          :disabled="verifying || !stepTwoReady"
          @click="createPayer"
          class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CoporateInfo",

  data() {
    return {
      verifying: false,
      form: {
        tin: null,
        email: null,
        phone: null,
        businessName: null,
        businessAddress: null,
      },
      ninData: this.$store.getters["general/ind"],
    };
  },

  computed: {
    tinReady() {
      return !!this.form.tin && this.form.tin.length >= 6;
    },
    stepOneReady() {
      return this.tinReady;
    },
    stepTwoReady() {
      console.log(this.$store.getters["general/ind"].stepDone);
      return (
        this.$store.getters["general/ind"].stepDone == 1 &&
        Boolean(this.form.email) &&
        Boolean(this.form.phone) &&
        Boolean(this.form.businessAddress) &&
        Boolean(this.form.businessName)
      );
    },
    emailOrPhoneReady() {
      return (
        (this.form.email &&
          (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.email
          ) ||
            /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.phone))) ||
        !this.form.phone
      );
    },
  },

  methods: {
    updateStore(step) {
      this.$store.commit("general/updateStep", step);
      this.$router.push({
        name: "Home",
        query: { st: step },
      });
    },
    prev() {
      const step = this.$store.state.general.step - 1;
      this.updateStore(step);
    },
    next() {
      const step = this.$store.state.general.step + 1;
      this.updateStore(step);
    },
    saveData(data) {
      this.$store.commit("general/updateInvoiceData", data);
    },
    confirmTin() {
      this.saveData({ ...this.form, stepDone: 1 });
      this.next();
    },
    async verifyTin() {
      try {
        this.saveData({ ...this.form, stepDone: 1 });

        this.verifying = true;

        const res = await this.$http.post(
          "/taxpayer/tin-payer",
          { tin: this.form.tin },
          {
            headers: { noauth: true },
          }
        );
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;

        // skip kyc for existing users
        if (data.code == "S200") {
          this.saveData({ ...data.data, stepDone: 1 });
          this.updateStore(5);
          this.$emit("done", data.data);
          return;
        }

        this.saveData({ stepDone: 1 });
        this.next();
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },

    async createPayer() {
      try {
        if (!this.stepTwoReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.verifying = true;

        const res = await this.$http.post(
          "/taxpayer/tin-profile",
          { ...this.form },
          {
            headers: { noauth: true },
          }
        );
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.saveData(data.data);

        this.updateStore(5);
        this.$emit("done", data.data);
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },
  },
};
</script>

<style scoped></style>
