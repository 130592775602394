<template>
  <div class="flex justify-content-between gap-3 mt-1">
    <HistoryLine :done="paymentData.done" />

    <div class="border border-gray-300 rounded p-6 mt-4 w-full">
      <div
        v-if="paymentData.done"
        class="flex flex-col gap-3 justify-center items-center"
      >
        <h4 class="font-bold text-cadet text-xs">Payment completed on</h4>

        <div class="flex gap-2 items-center mt-3">
          <div
            class="w-10 h-10 rounded-full bg-ansLemon flex items-center justify-center"
          >
            <img src="@/assets/images/icons/calendar.svg" alt="icons" />
          </div>

          <p class="text-xs">
            {{ paymentData.transactionDate | moment("DD/MM/YYYY") }}
          </p>
        </div>
      </div>

      <div v-else class="flex gap-3 justify-center">
        <h4 class="font-bold text-cadet text-xs">
          Payment has not been completed
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryLine from "@/components/track/HistoryLine.vue";
export default {
  name: "PaymentCard",

  components: {
    HistoryLine,
  },

  props: {
    paymentData: {
      type: Object,
      default: () => ({
        transactionDate: "2023-05-23 10:54:39",
        done: false,
      }),
    },
  },
};
</script>

<style scoped></style>
